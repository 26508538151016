<template>
  <div></div>
</template>

<script>
export default {
  name: 'WX',

  beforeMount () {
    this.loginByWXCode()
  },

  methods: {
    loginByWXCode () {
      const code = this.$route.query.code || ''
      if (!code) return this.$router.replace('/login')

      this.api.loginByWXCode({
        code
      }).then(res => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return
        }
        this.$store.commit('setToken', res.data)

        // 获取管理信息，包含权限
        this.api.getUserInfo().then(res2 => {
          const info = res2.data.getInfoByUser
          if (!info) return this.$message.error('登录失败：获取信息失败')
          this.$store.commit('setUserInfo', info)
          this.$router.replace(this.$store.state.currentPath)
        })
      }).catch(() => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<style scoped>

</style>
